
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class MultipleDaysCalendar extends Vue {  
  @Prop() private value!: string[];
  
  selectedDates:string[] = [];

  onDateSelected(date:string):void {
    const indexOfSelectedDate = this.selectedDates.indexOf(date);
    if (indexOfSelectedDate > -1) {
      this.selectedDates.splice(indexOfSelectedDate, 1);
    } else {
      this.selectedDates.push(date);
    }
  }

  dateClass(date: string): string {
    return this.selectedDates.indexOf(date) > -1 ? 'selected-date' : '';
  }

  @Watch('selectedDates')
  onDatesChange(): void {
    this.$emit('input', this.selectedDates);
  }
}
