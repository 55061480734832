
import { Component, Vue, Prop } from 'vue-property-decorator';
import FileUtil from '@/utils/file.util';

@Component
export default class ExportButton extends Vue {
  @Prop() private exportAction!: string;
  @Prop() private searchParams!: any;
  @Prop() private fileName!: string;
  @Prop({ default: false }) private emitClick!: boolean;

  async exportAndDownload(searchParams: any = this.searchParams): Promise<void> {
    if(!searchParams || !this.exportAction) return;

    const xls64 = await this.$store.dispatch(this.exportAction, searchParams);

    if (!xls64) {
      this.$store.commit('showAlert', {
        message: 'Não há dados disponíveis para exportar a consulta realizada.',
        type: 'warning',
      });
      return;
    }

    FileUtil.downloadFile(xls64, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', this.fileName);
  }
}
