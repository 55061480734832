
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { SearchProgramsSearchParamsDTO } from '@/data/dto/search-programs.dto';

@Component({})
export default class SearchProgramsForm extends Vue {  
  @Prop() private value!: Record<string, string>[];
  @Prop(String) private mainTvNetworkId?: string;

  programSearchTerm = '';
  programsList: Record<string, string>[] = [];

  async searchPrograms(): Promise<void> {
    try {
      const searchBody: SearchProgramsSearchParamsDTO = { term: this.programSearchTerm };
      if (this.mainTvNetworkId) searchBody.mainTvNetworkId = this.mainTvNetworkId;
      const { programs } = await this.$store.dispatch('searchPrograms', searchBody);
      this.programsList = programs;
      if (!programs.length) this.$store.commit('showAlert', { message: 'Nenhum programa encontrado.', type: 'success'});
    } catch (e) {
      this.programsList = [];
    }
  }

  @Watch('programsList')
  onSelectionValueChange(list: Record<string, string>[]): void {
    this.$emit('input', list);    
  }

  @Watch('value')
  onValueChanged(value:any): void {
    this.programsList = value;
  }
}
