export default class FileUtil {
  static downloadFile(data: ArrayBuffer, type: string, name: string): void {
    // blob
    const file = new Blob([data], { type });

    // url
    const url = URL.createObjectURL(file);

    // create and destroy link
    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    setTimeout(function() {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },0);
  }
}