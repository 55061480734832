
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ResearchPageHeader extends Vue {
  @Prop(String) private title?: string;

  get currentRouteLabel(): string {    
    return this.$route.meta && this.$route.meta.label ? this.$route.meta.label : '';
  }

  get pageTitle(): string {
    return this.title || this.currentRouteLabel;
  }
}
