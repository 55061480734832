
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ViewersProjectionBaseType } from '@/utils/number.util';

export type ChartDataType = 'share' | 'audience';

@Component
export default class DataFormatControlsContainer extends Vue {
  @Prop({ default: true }) private showDecimalPlacesController!: boolean;
  @Prop({ default: true }) private showDataTypeController!: boolean;
  @Prop({ default: true }) private showViewersProjectionBaseController!: boolean;
  @Prop({ default: true }) private useCardStyle!: boolean;
  @Prop({ default: 0 }) private defaultDecimalPlaces!:number;
  @Prop({ default: 'audience' }) private defaultDataType!: ChartDataType;
  @Prop({ default: 1000000 }) private defaultViewersProjectionBaseValue!: number;

  decimalPlaces = this.defaultDecimalPlaces;

  showShare = !!(this.defaultDataType === 'share');

  viewersProjectionBaseList: ViewersProjectionBaseType[] = [
    {
      value: 1,
      label: 'Nenhuma',
      baseSymbol: '',
      decimalPlaces: 0,
    },
    {
      value: 1000000,
      label: 'Milhão',
      baseSymbol: 'M',
      decimalPlaces: 1,
    },
  ];

  viewersProjectionBaseValue: number = this.defaultViewersProjectionBaseValue || this.viewersProjectionBaseList[0].value;

  get yAxisKey(): ChartDataType {
    return this.showShare ? 'share' : 'audience';
  }

  get viewersProjectionBase(): ViewersProjectionBaseType {
    const selectedViewersProjectionBase = this.viewersProjectionBaseList.find(projectionBase => projectionBase.value === this.viewersProjectionBaseValue);
    return selectedViewersProjectionBase || this.viewersProjectionBaseList[0];
  }

  @Watch('decimalPlaces')
  onDecimalPlacesChange(decimalPlaces: number) {
    this.$emit('decimalPlacesChanged', decimalPlaces);
  }

  @Watch('showShare')
  onDataTypeChange() {
    this.$emit('chartDataTypeChanged', this.yAxisKey);
  }
  
  @Watch('viewersProjectionBaseValue')
  onViewersProjectionBaseChange() {
    this.$emit('viewersProjectionBaseChanged', this.viewersProjectionBase);
  }
}
