export type TargetBaseType = 'residences' | 'population';

export const getTargetBaseTypeByTargetName = (name: string): TargetBaseType => {
  return name.substring(0, 3).toUpperCase() === "DOM" ? 'residences': 'population';
};

export const generateViewersProjectionLabel = (targetBaseType: TargetBaseType): string => {
  const labelsByType = {
    residences: 'DOM#',
    population: 'IND#',
  }
  return labelsByType[targetBaseType];
};
