
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import CustomSelectList from '@/components/CustomSelectList.vue';
import MultipleDaysCalendar from '@/components/MultipleDaysCalendar.vue';
import DateTimeUtil from '@/utils/datetime.util';
import ValidationUtil from '@/utils/validation.util';
import moment from 'moment';
import {
  ResearchFilterMarketDTO,
  ResearchFilterTvNetworkDTO, ResearchFilterTypes,
  ResearchFilterProgramDTO,
  ResearchFilterDTO,
} from '@/data/dto/research-filter.dto';
import SearchProgramsForm from '@/components/SearchProgramsForm.vue';
import { TVNetworkTypes } from '@/data/dto/tv-network-types.dto';

export type AvailableFields =  'MARKET' | 'TARGET' | 'MAIN_TV_NETWORK' |
                        'SECONDARY_TV_NETWORK' | 'CONNECTED_TVS_SUM_TYPE' | 'DEFAULT_WEEKDAYS' |
                        'PROGRAM' | 'START_DATE' | 'END_DATE' | 'SHARE' |
                        'START_TIME' | 'END_TIME' | 'CUSTOM_TIME_SLOT' |
                        'WEEKDAYS_CUSTOM_GROUP' | 'EQUIVALENT_PERIOD' | 'DAYS_TO_OMIT' |
                        'DECIMAL_PLACES' | 'RANKING_TIME_RANGES' | 'EXPANDED_GRID' |
                        'TV_NETWORK_TYPE';

export interface SearchParamsObject {
  completeData: Record<string, any>;
  valueOnly: Record<string, any>;
}

@Component({
  components: {
    CustomSelectList,
    MultipleDaysCalendar,
    SearchProgramsForm,
  }
})
export default class ResearchsSearchForm extends Vue {
  @Prop({ default: () => [] }) private fields!: AvailableFields[];
  @Prop() private getFiltersAction!: string;
  @Prop({ default: '' }) private minStartDate!: string;

  submitButtonIsDisabled = false;

  defaultMarketSetted = false;

  programSearchTerm = '';
  allowedPrograms: ResearchFilterProgramDTO[] = [];

  searchParamsMap: Record<AvailableFields, string> = {
    'MARKET': 'market',
    'TARGET': 'target',
    'MAIN_TV_NETWORK': 'mainTvNetwork',
    'SECONDARY_TV_NETWORK': 'secondaryTvNetworks',
    'CONNECTED_TVS_SUM_TYPE': 'connectedTvsSumType',
    'DEFAULT_WEEKDAYS': 'weekdays',
    'PROGRAM': 'program',
    'START_DATE': 'startDate',
    'END_DATE': 'endDate',
    'SHARE': 'share',
    'START_TIME': 'startTime',
    'END_TIME': 'endTime',
    'CUSTOM_TIME_SLOT': 'customTimeSlot',
    'WEEKDAYS_CUSTOM_GROUP': 'weekdaysCustomGroup',
    'EQUIVALENT_PERIOD': 'equivalentPeriod',
    'DAYS_TO_OMIT': 'daysToOmit',
    'DECIMAL_PLACES': 'decimalPlaces',
    'RANKING_TIME_RANGES': 'rankingTimeRanges',
    'EXPANDED_GRID': 'expandedGrid',
    'TV_NETWORK_TYPE': 'tvNetworkType',
  };

  filtersMap: Record<AvailableFields, string> = {
    'MARKET': 'markets',
    'TARGET': 'targets',
    'MAIN_TV_NETWORK': 'tvNetworks',
    'SECONDARY_TV_NETWORK': 'tvNetworks',
    'CONNECTED_TVS_SUM_TYPE': 'connectedTvsSumTypes',
    'DEFAULT_WEEKDAYS': 'weekdays',
    'PROGRAM': 'programs',
    'START_DATE': '',
    'END_DATE': '',
    'SHARE': '',
    'START_TIME': '',
    'END_TIME': '',
    'CUSTOM_TIME_SLOT': 'customTimeSlots',
    'WEEKDAYS_CUSTOM_GROUP': 'weekdaysCustomGroups',
    'EQUIVALENT_PERIOD': '',
    'DAYS_TO_OMIT': '',
    'DECIMAL_PLACES': '',
    'RANKING_TIME_RANGES': 'rankingTimeRanges',
    'EXPANDED_GRID': '',
    'TV_NETWORK_TYPE': 'tvNetworkTypes',
  }

  defaultFilters: Record<string, ResearchFilterTypes[]> = {
    markets: [],
    targets: [],
    tvNetworks: [],
    connectedTvsSumTypes: [],
    weekdays: [],
    programs: [],
    customTimeSlots: [],
    weekdaysCustomGroups: [],
    tvNetworkTypes: [],
  };
  filters = {...this.defaultFilters};

  defaultSearchParams: Record<string, any> = {
    market: '',
    target: '',
    mainTvNetwork: '',
    secondaryTvNetworks: [],
    connectedTvsSumType: '',
    weekdays: [],
    program: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    share: false,
    equivalentPeriod: false,
    weekdaysCustomGroup: '',
    customTimeSlot: '',
    daysToOmit: [],
    decimalPlaces: 0,
    expandedGrid: false,
    tvNetworkType: '',
  };

  searchParams = {...this.defaultSearchParams};

  lastAvailableDate: string | null = null;

  get hideSearchPrograms(): boolean {
    return !!this.allowedPrograms.length && this.searchParams.mainTvNetwork === 'GLO';
  }

  get maxDate(): string {
    return moment().subtract(1, 'days').format('YYYY-MM-DD');
  }

  get hasLimitToStartDate(): boolean {
    return this.minStartDate !== '';
  }

  get minDate(): string | null {
    return this.hasLimitToStartDate ? moment(this.minStartDate).format('YYYY-MM-DD') : null;
  }

  get startDateFormatted(): string {
    return moment(this.minStartDate).format('DD/MM/YYYY');
  }

  get startDateLabelHelp(): string {
    const labelText = `Datas disponíveis para consulta a partir de ${this.startDateFormatted}`;
    return this.hasLimitToStartDate ? labelText : '';
  }

  get availableTvNetworks(): ResearchFilterTvNetworkDTO[] {
    const tvs = this.filters.tvNetworks as ResearchFilterTvNetworkDTO[];
    if (this.searchParams.tvNetworkType === 'paytv') {
      return tvs.filter((tv) => tv.isPayTv);
    }
    return tvs;
  }

  get availablePrimaryTvNetworks(): ResearchFilterTvNetworkDTO[] {
    const tvs = this.availableTvNetworks;
    return tvs.filter((tv) => tv.isMain);
  }

  get availableSecondaryTvNetworks(): ResearchFilterTvNetworkDTO[] {
    const tvs = this.availableTvNetworks;
    if (!this.searchParams.mainTvNetwork) return tvs;
    return tvs.filter((tv) => tv.value != this.searchParams.mainTvNetwork);
  }

  get availableConnectedTvsSumTypes(): ResearchFilterDTO[] {
    const connectedTvsSumTypes = this.filters.connectedTvsSumTypes as ResearchFilterDTO[];
    if (this.searchParams.tvNetworkType !== 'paytv') {
      return connectedTvsSumTypes.filter((type) => type.value !== 'CPT');
    }
    return connectedTvsSumTypes;
  }

  get marketList(): Record<string, any>[] {
    const list = this.filters[this.filtersMap['MARKET']] as ResearchFilterMarketDTO[];
    return [
      {
        title: 'Regular',
        collection: list.filter((market) => !market.isSpecial),
      },
      {
        title: 'Caderno',
        collection: list.filter((market) => market.isSpecial),
      },
    ];
  }

  get lastAvailableDateText(): string {
    if (!this.searchParams.market) return '';

    if (!this.lastAvailableDate) {
      return 'Não há dados disponíveis para a praça selecionada';
    }

    const formattedDate = DateTimeUtil.stringDateDTOToView(this.lastAvailableDate);
    return `Última data disponível para a praça ${this.searchParams.market}: ${formattedDate}`;
  }

  fieldIncluded(field: AvailableFields): boolean {
    return this.fields.includes(field);
  }

  generateLabelFromList(field: AvailableFields, label = '') : string {
    const list = this.searchParams[this.searchParamsMap[field]];
    return `${label} (${list ? list.length : 0})`;
  }
  generateLabel(field: AvailableFields, emptyLabel = '', labelKey: 'value' | 'label' = 'value'): string {
    if (!field || !this.filtersMap[field]) return emptyLabel;

    const list = this.filters[this.filtersMap[field]];
    const value = this.searchParams[this.searchParamsMap[field]];
    if(!value || !list || !list.length) return emptyLabel;

    const item = list.filter(i => i.value === value)[0];
    return item ? item[labelKey] : emptyLabel;
  }

  // Geração do objeto com os filtros selecionados
  generateSearchParams(
    fields = this.fields,
    searchParams = this.searchParams,
    searchParamsMap = this.searchParamsMap,
    filters = this.filters,
    filtersMap = this.filtersMap,
  ): SearchParamsObject {

    // objeto contendo apenas os values dos filtros, usado para realizar as consultas de dados
    const valueOnly: Record<string, any> = {};
    // objeto completo, com todos os metadados de cada item selecionado,
    // usado para fornecer mais dados ao pai desse componente
    const completeData: Record<string, any> = {};

    fields.forEach((field:AvailableFields) => {
      const param = searchParamsMap[field];
      const filterListKey = filtersMap[field];
      const filterList = filterListKey ? filters[filterListKey] : null;

      if (Array.isArray(searchParams[param])) {
        valueOnly[param] = [...searchParams[param]];
        // verifica se a chave atual tem uma lista de filtros associada,
        // se tiver, procura o item da lista, senão usa o valor bruto
        if (filterList) {
          completeData[param] = searchParams[param]
            .map((item: any) =>  filterList.find(filter => filter.value === item));
        } else {
          completeData[param] = [...searchParams[param]];
        }
      } else {
        valueOnly[param] = searchParams[param];
        completeData[param] = filterList ? filterList.find(filter => filter.value === searchParams[param]) : searchParams[param];
      }
    });

    return { completeData, valueOnly };
  }

  async getFilters(): Promise<void> {
    try {
      const filters = await this.$store.dispatch(this.getFiltersAction);

      if (filters.programs && filters.programs.length) this.allowedPrograms = filters.programs;

      this.filters = {...filters};
      delete this.filters.defaultValues;
      if (filters.defaultValues) this.searchParams = {...this.searchParams, ...filters.defaultValues};

      this.onDefaultFiltersValuesLoaded();
    } catch (e) {
      console.log(e);
      this.filters = {...this.defaultFilters};
      this.searchParams = {...this.defaultSearchParams};
    }
  }

  // reações à mudanças de filtros
  get watchableSearchParams() {
    return {...this.searchParams};
  }

  @Watch('watchableSearchParams')
  onSearchParamsChange(newVal: Record<string, any>, oldVal: Record<string, any>): void {
    this.submitButtonIsDisabled = false;
    if (newVal.market != oldVal.market) this.setFiltersByMarket(newVal.market);
    if (newVal.program != oldVal.program) this.setDatesBasedOnProgramLastExhibition(newVal.program, this.searchParams.market, newVal.mainTvNetwork);
    if (newVal.startDate != oldVal.startDate) this.handleStartDateChange(newVal);
    if (newVal.mainTvNetwork != oldVal.mainTvNetwork) this.onMainTvChange(newVal.mainTvNetwork);
    if (newVal.tvNetworkType != oldVal.tvNetworkType) this.onTvNetworkTypeChange(newVal.tvNetworkType);
  }

  handleStartDateChange(newValue: Record<string, any>): void {
    this.setEndDateBasedOnStartDate(newValue.startDate);

    if (this.minDate && newValue.startDate < this.minDate) {
      this.submitButtonIsDisabled = true;
      const message = `Dados disponíveis para consulta a partir de ${this.startDateFormatted}.`;
      this.$store.commit('showAlert', { message, type: 'warning'});
    }
  }

  // altera as datas de acordo com a exibição do programa consultado
  async setFiltersByMarket(market: string): Promise<void> {
    if (!market) return;

    if (this.fieldIncluded("START_DATE") || this.fieldIncluded('END_DATE')) {
      const markets = this.filters.markets as ResearchFilterMarketDTO[];
      const selectedMarketLastAvailableDate = markets.find((marketItem) => marketItem.value === market)?.lastAvailableDate;
      this.lastAvailableDate = selectedMarketLastAvailableDate || null;
    }

    if (!this.defaultMarketSetted) {
      this.defaultMarketSetted = true;
      return;
    }

    try {
      const { targets, tvNetworks, connectedTvsSumTypes } = await this.$store.dispatch('getFiltersByMarket', { marketId: market });

      if (this.fieldIncluded('CONNECTED_TVS_SUM_TYPE')) {
        this.filters.connectedTvsSumTypes = connectedTvsSumTypes;
        if(!this.itemIsAvailableOnList(this.searchParams.connectedTvsSumType, this.filters.connectedTvsSumTypes)) {
          this.searchParams.connectedTvsSumType = this.filters.connectedTvsSumTypes?.[0]?.value;
        }
      }

      this.filters.tvNetworks = tvNetworks;
      this.filters.targets = targets;

      // tratamentos caso as seleções atuais estejam fora das opções disponíveis
      // mainTvNetwork - valida se a seleção atual é permitida para o novo market
      if (this.fieldIncluded('MAIN_TV_NETWORK')) {
        if (!this.itemIsAvailableOnList(this.searchParams.mainTvNetwork, this.filters.tvNetworks)) {
          this.searchParams.mainTvNetwork = this.filters.tvNetworks?.[0]?.value;
        }
      }

      // secondaryTvNetworks - seleciona todas as emissoras disponíveis para o novo market
      if (this.fieldIncluded('SECONDARY_TV_NETWORK')) {
        this.selectDefaultSecondaryTvNetworks();
      }

      // target - valida se a seleção atual é permitida para o novo market
      if (this.fieldIncluded('TARGET')) {
        if (!this.itemIsAvailableOnList(this.searchParams.target, this.filters.targets)) {
          this.searchParams.target = this.filters.targets?.[0]?.value;
        }
      }
    } catch (e) {
      console.error('SET FILTERS BY MARKET', e);
      this.filters.tvNetworks = [...this.defaultFilters.tvNetworks];
      this.filters.targets = [...this.defaultFilters.targets];
    }
  }

  itemIsAvailableOnList(item: string | boolean | number | undefined, list: ResearchFilterTypes[]): boolean {
    const result = list.filter(i => i.value === item);
    return result.length > 0;
  }

  // altera as datas de acordo com a exibição do programa consultado
  async setDatesBasedOnProgramLastExhibition(selectedProgramValue: string, market: string, mainTvNetworkId: string): Promise<void> {
    if (!selectedProgramValue) return;

    const programs = this.filters[this.filtersMap['PROGRAM']] as ResearchFilterProgramDTO[];

    const program = programs.find((program) => program.value === selectedProgramValue);

    try {
      const { lastExhibitionDate } = await this.$store.dispatch('getProgramLastExhibitionDate', {
        programId: program?.program,
        presentationId: program?.presentation,
        programBoardId: program?.programBoard,
        marketId: market,
        mainTvNetworkId,
      });
      this.setDates(lastExhibitionDate);
    } catch (e) {
      console.log('SET DATES BASED ON PROGRAM LAST EXHIBITION::', e);
      this.$store.commit('showAlert', { message : "Não foram encontrados dados da última exibição.", type: 'warning'});
      return;
    }
  }

  // garante que a data final não é anterior a data inicial
  setEndDateBasedOnStartDate(startDate: string): void {
    try {
      if (moment(startDate).isAfter(this.searchParams.endDate)) {
        this.searchParams.endDate = startDate;
      }
    } catch (e) {
      return;
    }
  }

  setDates(date: string): void {
    if (this.fieldIncluded('END_DATE')) {
      this.searchParams.endDate = date;
    }

    if (this.fieldIncluded('START_DATE') && this.fieldIncluded('END_DATE')) {
      this.searchParams.startDate = moment(date).subtract(1, 'months').format('YYYY-MM-DD');
      return;
    }

    if (this.fieldIncluded('START_DATE')) {
      this.searchParams.startDate = date;
    }
  }

  selectDefaultSecondaryTvNetworks(): void {
    this.$nextTick(() => {
      this.searchParams.secondaryTvNetworks = this.availableSecondaryTvNetworks
        .filter((tvNetwork) => !!tvNetwork.selectedByDefault)
        .map((tvNetwork) => tvNetwork.value);
    });
  }

  onMainTvChange(mainTvNetwork: string): void {
    // reseta a lista de programas
    this.searchParams.program = '';
    this.filters.programs = [];

    // carrega a lista de programas permitidos para a Globo
    if (this.hideSearchPrograms) this.filters.programs = this.allowedPrograms;

    // remove a emissora principal da lista de emissoras secundárias
    if (this.searchParams.secondaryTvNetworks && this.searchParams.secondaryTvNetworks.length) {
      for(let i=0; i < this.searchParams.secondaryTvNetworks.length; i++) {
        const tv = this.searchParams.secondaryTvNetworks[i];
        if (tv === mainTvNetwork) {
          this.searchParams.secondaryTvNetworks.splice(i, 1);
          break;
        }
      }
    }

    this.selectDefaultSecondaryTvNetworks();
  }

  onTvNetworkTypeChange(type: TVNetworkTypes): void {
    if (this.fieldIncluded('CONNECTED_TVS_SUM_TYPE')) {
      if (!this.itemIsAvailableOnList(this.searchParams.connectedTvsSumType, this.availableConnectedTvsSumTypes)) {
        this.searchParams.connectedTvsSumType = this.availableConnectedTvsSumTypes[0]?.value;
      }
    }
  }

  @Watch('availablePrimaryTvNetworks')
  onAvailablePrimaryTvNetworksChange(availablePrimaryTvNetworks: ResearchFilterTvNetworkDTO[]): void {
    if (!availablePrimaryTvNetworks?.length) {
      this.searchParams.mainTvNetwork = null;
      return;
    }

    if (this.fieldIncluded('MAIN_TV_NETWORK')) {
      if (!this.itemIsAvailableOnList(this.searchParams.mainTvNetwork, availablePrimaryTvNetworks)) {
        this.searchParams.mainTvNetwork = availablePrimaryTvNetworks[0]?.value;
      }
    }
  }

  @Watch('availableSecondaryTvNetworks')
  onAvailableSecondaryTvNetworksChange(availableSecondaryTvNetworks: ResearchFilterTvNetworkDTO[]): void {
    if (!availableSecondaryTvNetworks?.length) {
      this.searchParams.secondaryTvNetworks = [];
      return;
    }

    if (this.fieldIncluded('SECONDARY_TV_NETWORK')) {
      this.searchParams.secondaryTvNetworks = this.searchParams.secondaryTvNetworks.filter((tv: string) => {
        return this.itemIsAvailableOnList(tv, availableSecondaryTvNetworks);
      });
    }
  }

  onDefaultFiltersValuesLoaded(): void {
    const searchParams = this.generateSearchParams();
    this.$emit('defaultFiltersValuesLoaded', searchParams);
  }

  searchSubmit(): void {
    const searchParams = this.generateSearchParams();
    try {
      this.validateForm(searchParams.valueOnly);
      this.$emit('searchSubmitted', searchParams);
    } catch (e: any) {
      const message = e.message ? e.message : 'Ocorreu um erro';
      this.$store.commit('showAlert', { message, type: 'danger'});
    }
  }

  validateForm(searchParamsValues: Record<string, any>): void {
    const fieldsValidations: any = {
     'program': () => {
        return {
          isValid: ValidationUtil.hasRequiredField(searchParamsValues['program']),
          errorMessage: 'Por favor, selecione um programa.',
        };
      },
      'weekdays': () => {
        return {
          isValid: ValidationUtil.listContainsAtLeastOneItem(searchParamsValues['weekdays']),
          errorMessage: 'É necessário selecionar ao menos um dia da semana.',
        };
      },
      'mainTvNetwork': () => {
        return {
          isValid: ValidationUtil.hasRequiredField(searchParamsValues['mainTvNetwork']),
          errorMessage: 'É necessário selecionar uma emissora como protagonista.',
        };
      },
      'secondaryTvNetworks': () => {
        return {
          isValid: ValidationUtil.listContainsAtLeastOneItem(searchParamsValues['secondaryTvNetworks']),
          errorMessage: 'É necessário selecionar ao menos uma emissora antagonista.',
        };
      },
      'startDate': () => {
        return {
          isValid: ValidationUtil.dateIsAfterOrEqualMinimumDate(searchParamsValues['startDate'], '2008-01-01') ||
            searchParamsValues['connectedTvsSumType'] !== 'TLE',
          errorMessage: 'Não existem dados para TLE antes de 2008.',
        };
      },
    };

    for (let searchParam in searchParamsValues) {
      const field = fieldsValidations[searchParam] ? fieldsValidations[searchParam]() : null;
      if (field && !field.isValid) throw new Error(field.errorMessage);
    }
  }


  async mounted(): Promise<void> {
    this.getFilters();
  }

}
