export type ViewersProjectionBaseType = {
  value: number,
  label: string,
  baseSymbol: string,
  decimalPlaces: number,
}

export const round = (num: number, decimals = 0): number => {
  const base = (10 ** decimals);
  return Math.round(num * base) / (base);
}

export const roundToUp = (num: number, decimals = 0): number => {
  const base = (10 ** decimals);
  return Math.ceil(num * base) / (base);
}

export const roundToDown = (num: number, decimals = 0): number => {
  const base = (10 ** decimals);
  return Math.floor(num * base) / (base);
}

export const numberToBrString = (value: number | undefined, decimalPlaces?: number): string => {
  if (typeof value !== 'number') return '';

  const minimumFractionDigits = decimalPlaces || 0;
  const maximumFractionDigits = typeof decimalPlaces === 'number' ? decimalPlaces : 10;

  return value.toLocaleString('pt-BR', { minimumFractionDigits, maximumFractionDigits });
}

export const formatNumberToStringWithSymbol = (num: number, base: ViewersProjectionBaseType): string => {
  if (!num) return '-';
  const basedValue = num / base.value;
  const roundedValue = round(basedValue, base.decimalPlaces);
  const formattedValue = numberToBrString(roundedValue, base.decimalPlaces);
  return `${formattedValue}${base.baseSymbol}`;
}
